class Validation {
  onKeyPressTextOnly(event) {
    const re = /[a-zA-Z_.]+( [a-zA-Z_.]+)*$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPressNumberOnly(event) {
    const re = /[0-9]+( [0-9]+)*$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPressNumberWithDot(event) {
    const re = /[0-9.]+( [0-9.]+)*$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPressNumberAndTextOnly(event) {
    const re = /[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPressUserName(event) {
    const re = /[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPressNumberAndTextWithMarks(event) {
    const re = /[a-zA-Z0-9.-]+( [a-zA-Z0-9.-]+)*$/g;
    if (!re.test(event.key)) {
      event.preventDefault();
    }
  }
  onKeyPressRetrisctSomeSC(event) {
    const re = /[|":<>[\]{}`\\()';]/g;
    if (re.test(event.key)) {
      event.preventDefault();
    }
  }

  //Excel Validation
  checkForDigitsOnly(dataFiles) {
    dataFiles.map((item, index) => {
      let result = "";
      let mobileRegex = /^\[0-9]{10}$/;
      if (item.Mobile_Number !== "") {
        if (!mobileRegex.test(item.Mobile_Number)) {
          result = false;
        } else {
          result = true;
        }
      }
      return result;
    });
  }
  checkForTextOnly(text) {
    let textRegex = /[a-zA-Z_.]+( [a-zA-Z_.]+)*$/g;
    if (!textRegex.test(text)) {
      return false;
    } else {
      return true;
    }
  }
  checkSATSID(id) {
    let satRegex = /\d{11}/;
    console.log(satRegex.test(id), id);
    if (!satRegex.test(id)) {
      return false;
    } else {
      return true;
    }
  }
  checkMobileNo(no) {
    let mobileNoRegex = /^[6-9][0-9]{9}$/;
    if (!mobileNoRegex.test(no)) {
      return false;
    } else {
      return true;
    }
  }
  checkForNumberOnly(no) {
    let numberRegex = /^\d+(\.\d+)?$/;
    if (!numberRegex.test(no)) {
      return false;
    } else {
      return true;
    }
  }
  validateFileSize(file) {
    if (file) {
      let size = 5242880;
      if (file.size > size) {
        return false;
      }
      return true;
    }
  }
  validatePdfFileType(file) {
    if (file) {
      if (!file.type.match("application/pdf")) {
        return false;
      }
      return true;
    }
  }
  validateExcelFileType(file) {
    if (file) {
      if (
        !file.type.match(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        return false;
      }
      return true;
    }
  }
  validateEmptyExcel(file) {
    console.log(file.size);
    if (file) {
      let size = 6164;
      if (file.size <= size) {
        return false;
      }
      return true;
    }
  }
  validateImageFileType(file) {
    if (file) {
      if (!file.type.match("image/jpeg")) {
        return false;
      }
      return true;
    }
  }
  getStartDate(year) {
    let startYear = year.substring(2, 4);
    console.log(startYear, "recived");
    return "20" + startYear + "-04-01";
  }
  getEndDate(year) {
    let endYear = year.substring(5);
    return "20" + endYear + "-03-31";
  }
  checkEmailID(id) {
    let emailIDRegex = /\S+@\S+\.\S+/;
    console.log(emailIDRegex.test(id), id);
    if (!emailIDRegex.test(id)) {
      return false;
    } else {
      return true;
    }
  }
  checkGSTIN(gstIN) {
    let gstINRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    console.log(gstINRegex.test(gstIN), gstIN);
    if (!gstINRegex.test(gstIN)) {
      return false;
    } else {
      return true;
    }
  }
  checkPassword(password) {
    let passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      return false;
    } else {
      return true;
    }
  }
}
export default new Validation();
