import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { Image } from "react-bootstrap";
import MetaTags from "react-meta-tags";

import Training from "assets/images/HeaderImages/Training.png";
import Aos from "aos";

import "assets/style/Style.css";

function LandingPageTraining() {
  useEffect(() => {
    Aos.init({});
  }, []);

  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header clear-filter page-header-small">
        <MetaTags>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
        </MetaTags>
        <div className="page-header-image" ref={pageHeader}>
          <div className="landing_backgroundimages">
            <Row>
              <Col>
                <h1 data-aos="fade-right" className="landing_aboutusfont">
                  Training
                </h1>
              </Col>
              <Col>
                <Image
                  data-aos="fade-up-left"
                  className="landing_aboutusimage"
                  src={Training}
                  roundedCircle
                  style={{ marginLeft: "4rem" }}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default LandingPageTraining;
