const images = {
  OilBlending: require("../assets/images/EmbeddedService/OilBlending.jpg")
    .default,
  TimeLogger: require("../assets/images/EmbeddedService/TimeLogger.jpg")
    .default,
  WirelessMotor: require("../assets/images/EmbeddedService/WirelessMotor.jpg")
    .default,
  // Add more images as needed
};

export const getImage = (imageName) => {
  return images[imageName] || null; // Return the image if it exists, otherwise return null
};
