const images = {
  Rpi: require("../assets/images/TechLogo/PNG/Rpi.png").default,
  Arduino: require("../assets/images/TechLogo/PNG/Arduino.png").default,
  Kicad: require("../assets/images/TechLogo/PNG/Kicad.png").default,
  Keil: require("../assets/images/TechLogo/PNG/Keil.png").default,
  Atmel: require("../assets/images/TechLogo/PNG/Atmel.png").default,
  Avr: require("../assets/images/TechLogo/PNG/Avr.png").default,
  NodeMCU: require("../assets/images/TechLogo/PNG/NodeMCU.png").default,
  Espre: require("../assets/images/TechLogo/PNG/Espre.png").default,
  // Add more images as needed
};

export const getImage = (imageName) => {
  return images[imageName] || null; // Return the image if it exists, otherwise return null
};
