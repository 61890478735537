const images = {
  Aditya: require("../assets/images/Testimonial/Aditya.jpg").default,
  Kiran: require("../assets/images/Testimonial/Kiran.jpeg").default,
  Nakul: require("../assets/images/Testimonial/Nakul.JPG").default,
  Niveditha: require("../assets/images/Testimonial/Niveditha.jpg").default,
  Upendra: require("../assets/images/Testimonial/Upendra.jpg").default,
  Vidyashankar: require("../assets/images/Testimonial/Vidyashankar.JPEG")
    .default,
  Gowrav: require("../assets/images/Testimonial/gowrav.jpeg").default,
  Sachin: require("../assets/images/Testimonial/Sachin.jpg").default,
  // Add more images as needed
};

export const getImage = (imageName) => {
  return images[imageName] || null; // Return the image if it exists, otherwise return null
};
