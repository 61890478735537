const images = {
  Vtech: require("../assets/images/Clients/PNG/Vtech.png").default,
  Ibbani: require("../assets/images/Clients/PNG/Ibbani.png").default,
  Vaml: require("../assets/images/Clients/PNG/Vaml.png").default,
  Vfb: require("../assets/images/Clients/PNG/Vfb.png").default,
  Kipl: require("../assets/images/Clients/PNG/Kipl.png").default,
  Sgb: require("../assets/images/Clients/PNG/Sgb.png").default,
  Sumukha: require("../assets/images/Clients/PNG/Sumukha.png").default,
  Techno: require("../assets/images/Clients/PNG/Techno.png").default,
  Uahs: require("../assets/images/Clients/PNG/Uahs.png").default,
  Nes: require("../assets/images/Clients/PNG/Nes.png").default,
  Jnnce: require("../assets/images/Clients/PNG/Jnnce.png").default,
  Gfgc: require("../assets/images/Clients/PNG/Gfgc.png").default,
  Vishwa: require("../assets/images/Clients/PNG/Vishwa.png").default,
  Amst: require("../assets/images/Clients/PNG/Amst.png").default,
  Clinical: require("../assets/images/Clients/PNG/Clinical.png").default,
  Glwc: require("../assets/images/Clients/PNG/Glwc.png").default,
  Supari: require("../assets/images/Clients/PNG/Supari.png").default,
  Malnadkitchen: require("../assets/images/Clients/PNG/Malnadkitchen.png")
    .default,
  MrMillet: require("../assets/images/Clients/PNG/MrMillet.jpeg").default,
  Rootsgoods: require("../assets/images/Clients/PNG/Rootsgoods.png").default,
  Nano: require("../assets/images/Clients/PNG/Nano.png").default,
  Arshiraj: require("../assets/images/Clients/PNG/Arshiraj.png").default,
  Benz: require("../assets/images/Clients/PNG/Benz.png").default,
  Church: require("../assets/images/Clients/PNG/Church.png").default,
  Monica: require("../assets/images/Clients/PNG/Monica.png").default,
  Pgp: require("../assets/images/Clients/PNG/Pgp.png").default,
  Scholar: require("../assets/images/Clients/PNG/Scholar.png").default,
  Cedlabs: require("../assets/images/Clients/PNG/Cedlabs.png").default,
  // Add more images as needed
};

export const getImage = (imageName) => {
  return images[imageName] || null; // Return the image if it exists, otherwise return null
};
