import React from "react";
import { Card, Button, Row } from "react-bootstrap";
import { getImage } from "../utils/imageUtils";

function SuccessStoriesData(props) {
  const { data } = props;

  return (
    <div>
      <Row style={{ display: "flex" }}>
        {data.data.map((s) => {
          const imageSrc = getImage(s.img);
          console.log("imagesrc = " + imageSrc);
          return (
            <Card className="ss-card-size" key={s.id}>
              <Card.Img variant="top" src={imageSrc} alt={s.title} />
              <Card.Body>
                <Card.Title>
                  <h6 className="successstories_headingalign">{s.title}</h6>
                </Card.Title>
                <Card.Text className="success-stories-content-font">
                  {s.successStories}
                </Card.Text>
                <Button
                  href={s.link}
                  variant="outline-danger"
                  size="sm"
                  target="_blank"
                >
                  Go somewhere
                </Button>
              </Card.Body>
            </Card>
          );
        })}
      </Row>
    </div>
  );
}

export default SuccessStoriesData;
