const images = {
  Html: require("../assets/images/TechLogo/PNG/Html.png").default,
  Css: require("../assets/images/TechLogo/PNG/Css.png").default,
  Bootstrap: require("../assets/images/TechLogo/PNG/Bootstrap.png").default,
  Angular: require("../assets/images/TechLogo/PNG/Angular.png").default,
  React: require("../assets/images/TechLogo/PNG/React.png").default,
  Node: require("../assets/images/TechLogo/PNG/Node.png").default,
  Mysql: require("../assets/images/TechLogo/PNG/Mysql.png").default,
  Mongo: require("../assets/images/TechLogo/PNG/Mongo.png").default,
  Firebase: require("../assets/images/TechLogo/PNG/Firebase.png").default,
  Aws: require("../assets/images/TechLogo/PNG/Aws.png").default,
  Php: require("../assets/images/TechLogo/PNG/Php.png").default,
  Spring: require("../assets/images/TechLogo/PNG/Spring.png").default,
  Java: require("../assets/images/TechLogo/PNG/Java.png").default,
  Android: require("../assets/images/TechLogo/PNG/Android.png").default,
  Ionic: require("../assets/images/TechLogo/PNG/Ionic.png").default,
  Flutter: require("../assets/images/TechLogo/PNG/Flutter.png").default,
  Python: require("../assets/images/TechLogo/PNG/Python.png").default,
  Xcode: require("../assets/images/TechLogo/PNG/Xcode.png").default,
  Figma: require("../assets/images/TechLogo/PNG/Figma.png").default,
  Corel: require("../assets/images/TechLogo/PNG/Corel.png").default,
  Photoshop: require("../assets/images/TechLogo/PNG/Photoshop.png").default,
  Illustrator: require("../assets/images/TechLogo/PNG/Illustrator.png").default,
  Premiere: require("../assets/images/TechLogo/PNG/Premiere.png").default,
  // Add more images as needed
};

export const getImage = (imageName) => {
  return images[imageName] || null; // Return the image if it exists, otherwise return null
};
