import React from "react";
import Carousel from "react-multi-carousel";
import { Image, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { getImage } from "utils/imageUtilsEmbeddedSuccess";

function EmbeddedSuccessStories(props) {
  const { data } = props;
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <div>
        <Carousel
          autoPlay={true}
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={3500}
        >
          {data.data.map((em) => {
            const imageSrc = getImage(em.img);
            return (
              <Row>
                <div className="embedded_contentspacing">
                  <p className="aboutus_textalign">
                    <strong className="aboutus_fontbold">{em.heading}</strong>
                  </p>
                  <Row data-aos="fade-up" data-aos-duration="3000">
                    <Col sm={6}>
                      <Link to="/success-story">
                        <Image
                          src={imageSrc}
                          alt={em.altText}
                          className="center"
                        />
                      </Link>
                    </Col>

                    <Col>
                      <div className="embedded_contentspacing">
                        <br />
                        <p className="it_sidecontentfontsize">
                          <strong>
                            {em.success}
                            <br />
                          </strong>
                        </p>
                        <p className="it_sidecontentfontsize">
                          <strong className="it_ssnamesize aboutus_fontbold">
                            <span className="it_ssnamecolor">{em.name},</span>
                            <br />
                            {em.company}, <br />
                            {em.location}
                          </strong>
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Row>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

export default EmbeddedSuccessStories;
