const images = {
  IBBANIs: require("../assets/images/Snapshots/IBBANIs.png").default,
  VTECHs: require("../assets/images/Snapshots/VTECHs.png").default,
  ROOTSGOODSs: require("../assets/images/Snapshots/ROOTSGOODSs.png").default,
  MONICAs: require("../assets/images/Snapshots/MONICAs.png").default,
  GFGCs: require("../assets/images/Snapshots/GFGCs.png").default,
  GLWCs: require("../assets/images/Snapshots/GLWCs.png").default,
  VAMLs: require("../assets/images/Snapshots/VAMLs.png").default,
  MORINGAs: require("../assets/images/Snapshots/MORINGAs.png").default,
  VISHWAs: require("../assets/images/Snapshots/VISHWAs.png").default,
  SUMUKHAs: require("../assets/images/Snapshots/SUMUKHAs.png").default,
  MALNADs: require("../assets/images/Snapshots/MALNADs.png").default,
  SCHOLARs: require("../assets/images/Snapshots/SCHOLARs.png").default,
  CLINICALs: require("../assets/images/Snapshots/CLINICALs.png").default,
  CSIs: require("../assets/images/Snapshots/CSIs.png").default,
  DSCs: require("../assets/images/Snapshots/DSCs.png").default,
  AMSTs: require("../assets/images/Snapshots/AMSTs.png").default,
  ISVMs: require("../assets/images/Snapshots/ISVMs.png").default,
  RGA: require("../assets/images/Snapshots/RGA.jpg").default,
  TL: require("../assets/images/Snapshots/TL.png").default,
  EILEARN: require("../assets/images/Snapshots/EILEARN.png").default,
  // Add more images as needed
};

export const getImage = (imageName) => {
  return images[imageName] || null; // Return the image if it exists, otherwise return null
};
